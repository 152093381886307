<template>
  <validation-observer ref="simpleRules">
    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="section-title pl-2 pt-2 pr-2">
                <span class="font-weight-bolder">{{ $t('qc-3') }}</span>
              </div>
              <div class="pl-2 pr-2">
                <b-row>
                  <b-col lg="3" />
                  <b-col lg="6">
                    <b-form-group
                      label-for="h-role-name"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('item') }} <span class="text-danger">*</span> :
                      </template>
                      <div>
                        <div>
                          <!-- <b-badge
                            v-for="(item,index) in QcData.qc_payment"
                            :key="index"
                            variant="primary"
                            class="mb-25 mr-25"
                          >
                            <span>{{ item.payment_name }}</span>
                            <feather-icon
                              icon="XIcon"
                              class="ml-25 cursor-pointer"
                              @click="DeletePayment(item)"
                            />
                          </b-badge> -->
                        </div>
                        <b-input-group>
                          <b-form-input
                            v-model="find_payment_name"
                            placeholder=""
                            @input="checkInput"
                            @keyup.enter="FindPostnumber()"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              :disabled="find_payment_name === ''"
                              @click="FindPostnumber()"
                            >
                              {{ $t('search') }}
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3" />

                  <b-col lg="3" />
                  <b-col lg="6">
                    <b-form-group
                      label-for="login-password"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('remarks') }} :
                      </template>
                      <b-form-textarea
                        v-model="QcData.remark"
                        row="3"
                        @change="UpdataRemark()"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="3" />
                </b-row>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card">
              <div class="section-title pl-2 pt-2 pr-2">
                <span class="font-weight-bolder">{{ $t('qc') }}</span>
              </div>
              <div class="pl-2 pr-2">
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :current-page="PageQcWait"
                  :items="qcWait"
                  :fields="qcFieldsWait"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                >
                  <template #empty>
                    <div class="text-center">
                      <img
                        src="@/assets/images/anan-img/empty/empty.png"
                        alt="empty"
                        width="180px"
                      >
                      <p>No Data</p>
                    </div>
                  </template>
                  <template #cell(sequence)="data">
                    {{ (perPage * (PageQcWait - 1) + (data.index + 1)) }}
                  </template>
                  <template #cell(lot_name)="data">
                    {{ data.item.lot_name ? `${data.item.lot_name}/${data.item.lot_order}`: 'ไม่มีล๊อต' }}
                  </template>
                  <template #cell(qc_fileimg)="data">
                    <img
                      v-if="data.item.qc_fileimg"
                      :src="GetImg('QCPostnumber',data.item.qc_fileimg)"
                      alt="Preview"
                      width="100px"
                      height="50px"
                      @click="showPicture(data.item.qc_fileimg)"
                    >
                    <span v-else>-</span>
                    <vue-image-lightbox-carousel
                      ref="lightbox"
                      :show="showLightbox"
                      :images="images"
                      :show-caption="false"
                      @close="closeBox()"
                    />

                  </template>
                  <template #cell(qc_date)="data">
                    {{ time(data.item.qc_date) }}
                  </template>
                  <!--  -->
                  <template #cell(cmb)="data">
                    {{ data.item.width && data.item.long && data.item.height ? Commas((data.item.width*data.item.long*data.item.height)/1000000) : 0 }}
                  </template>
                  <template #cell(actions)="data">
                    <!-- <b-button
                      variant="danger"
                      size="sm"
                      @click="DeleteData(data.item)"
                    > -->
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-1 text-primary cursor-pointer"
                      @click="getpost(data.item)"
                    />
                    <i
                      class="fal fa-trash-alt"
                      @click="DeleteDataWait(data.item)"
                    />

                    <!-- </b-button> -->
                  </template>
                </b-table>

                <div class="demo-spacing-0 d-flex justify-content-end mb-2">
                  <b-pagination
                    v-model="PageQcWait"
                    :total-rows="totalQcWait"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card">
              <div class="section-title pl-2 pt-2 pr-2">
                <span class="font-weight-bolder">{{ $t('qc-0') }}</span>
              </div>
              <div class="d-flex align-items-center col-md-6">
                <b-form-group class="w-75">
                  <validation-provider
                    #default="{ errors }"
                    name="Parcel Code"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <i class="fal fa-barcode-read" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="h-parcel-code"
                        v-model="parcelCode"
                        :state="errors.length > 0 ? false : null"
                        @input="checkInputWait"
                        @keyup.enter="addParcelCode()"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>

                <b-button
                  variant="primary"
                  class="ml-50 w-25 mt-n1"
                  @click="addParcelCode()"
                >
                  {{ $t('qc-1') }}
                </b-button>
              </div>
              <div class="pl-2 pr-2">
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :current-page="PageQcSuccess"
                  :items="qcSuccess"
                  :fields="qcFieldsSuccess"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                >
                  <template #empty>
                    <div class="text-center">
                      <img
                        src="@/assets/images/anan-img/empty/empty.png"
                        alt="empty"
                        width="180px"
                      >
                      <p>No Data</p>
                    </div>
                  </template>
                  <template #cell(sequence)="data">
                    {{ (perPage * (PageQcWait - 1) + (data.index + 1)) }}
                  </template>
                  <template #cell(lot_name)="data">
                    {{ data.item.lot_name }}/{{ data.item.lot_order }}
                  </template>
                  <template #cell(cmb)="data">
                    {{ data.item.width && data.item.long && data.item.height ? Commas((data.item.width*data.item.long*data.item.height)/1000000) : 0 }}
                  </template>
                  <template #cell(qc_date)="data">
                    {{ time(data.item.qc_date) }}
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="DeleteData(data.item)"
                    >
                      <i class="fal fa-trash-alt" />
                    </b-button>
                  </template></b-table>

                <div class="demo-spacing-0 d-flex justify-content-end mb-2">
                  <b-pagination
                    v-model="PageQcSuccess"
                    :total-rows="totalQcSuccess"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="lot-modal"
        :title="$t('key-296')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok.prevent="SubmitLot()"
      >

        <!-- <div class="mb-12 p-2">
          <v-select
            id="h-lotall"
            v-model="LotDataID"
            label="text"
            :options="LotDataList"
            class="style-chooser"
            @input="getpost(LotDataID)"
          />
        </div> -->
        <div
          v-if="OnPostnumber"
          class=""
        >
          <b-col md="12">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="h-product-weight"
                  label-cols="4"
                  label-cols-lg="4"
                  label-cols-md="4"
                >
                  <template v-slot:label>
                    {{ $t('weight') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="product-weight"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="h-product-weight"
                        v-model="productWeight"
                        type="number"
                        placeholder="0"
                        :state="errors.length > 0 ? false:null"
                        @input="checkedprice()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('key-116') }} </b-input-group-append>
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] ? 'กรุณากรอกน้ำหนัก' : '' }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label-for="h-product-height"
                  label-cols="4"
                  label-cols-lg="4"
                  label-cols-md="4"
                >
                  <template v-slot:label>
                    {{ $t('height') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="product-height"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="h-product-height"
                        v-model="productHeight"
                        type="number"
                        placeholder="0"
                        :state="errors.length > 0 ? false:null"
                        @input="checkedpricereq()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('cm') }}
                      </b-input-group-append>
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] ? 'กรุณากรอกความสูง' : '' }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label-for="h-product-width"
                  label-cols="4"
                  label-cols-lg="4"
                  label-cols-md="4"
                >
                  <template v-slot:label>
                    {{ $t('widthCm') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="product-width"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="h-product-width"
                        v-model="productWidth"
                        type="number"
                        placeholder="0"
                        :state="errors.length > 0 ? false:null"
                        @input="checkedpricereq()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('cm') }}
                      </b-input-group-append>
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] ? 'กรุณากรอกความกว้าง' : '' }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label-for="h-product-long"
                  label-cols="4"
                  label-cols-lg="4"
                  label-cols-md="4"
                >
                  <template v-slot:label>
                    {{ $t('lengthCm') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="product-long"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="h-product-long"
                        v-model="productLong"
                        type="number"
                        placeholder="0"
                        :state="errors.length > 0 ? false:null"
                        @input="checkedpricereq()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('cm') }}
                      </b-input-group-append>
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] ? 'กรุณากรอกความยาว' : '' }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="12"
            class="text-center mb-1"
          >
            <p class="h5 mb-0 mt-50">
              {{ $t('key-326') }}  {{ checksave() }} ฿
            </p>
          </b-col>

          <b-col md="12">
            <b-form-group
              label-for="h-select-price"
              label-cols="2"
              label-cols-lg="2"
              label-cols-md="3"
            >
              <template v-slot:label>
                {{ $t('key-121') }} </template>
              <div>
                <b-form-radio
                  v-model="selectPrice"
                  name="some-selectPrice"
                  :value="1"
                  class="custom-control-primary mb-50 mt-50"
                >
                  {{ $t('key-175') }} {{ (Commas(charge_per_kilo)) }} ฿
                </b-form-radio>

                <b-form-radio
                  v-model="selectPrice"
                  name="some-selectPrice"
                  :value="2"
                  class="custom-control-primary"
                >
                  {{ $t('key-0') }} {{ (Commas(charge_per_cue)) }} ฿
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div
              v-if="fileimg"
              class="mr-25 d-flex justify align-items-center position-relative"
              style="height: 120px; width: 90px; border: 1px solid #ccc; border-radius: 5px; overflow: hidden; object-fit: cover;"
            >
              <img
                :src="GetImg('QCPostnumber',fileimg)"
                alt="Preview"
                width="100%"
                height="auto"
                @click="showPicture(fileimg)"
              >
              <vue-image-lightbox-carousel
                ref="lightbox"
                :show="showLightbox"
                :images="images"
                :show-caption="false"
                @close="closeBox()"
              />
              <div
                class="p-0"
                style="position: absolute; top: 0; right: 0;"
              >
                <i
                  class="fas fa-times-circle cursor-pointer text-danger"
                  @click="Deletefileimg()"
                />
              </div>
            </div>
            <div
              v-else
              class="box-input-file d-flex justify-content-center align-items-center position-relative"
            >
              <i class="fal fa-plus cursor-pointer mr-50" />{{ $t('key-9') }}
              <input
                id="img-file"
                ref="fileInput"
                type="file"
                class="input-file"
                accept="image/*"
                multiple
                @change="handleFileChanges($event)"
              >
            </div>

          </b-col>

        </div>

      </b-modal>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BOverlay, BInputGroupPrepend, BRow, BCol, BTable, BFormGroup, BFormInput, BFormTextarea,
  BPagination, BInputGroup, BInputGroupAppend, BButton,
  // BBadge,
  BModal,
  // BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    // BBadge,
    BFormRadio,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BModal,
    // BFormRadioGroup,
    vSelect,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      totalQcWait: 0,
      PageQcWait: 1,
      totalQcSuccess: 0,
      PageQcSuccess: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      qcWait: [],
      qcSuccess: [],
      QcData: [],
      find_payment_name: '',
      ID: this.$route.params.id,
      parcelCode: '',
      showOver: false,
      PostData: {},
      LotDataList: [],
      LotDataID: '',
      LotData: {},
      OnPostnumber: null,
      checkpost: false,
      productLong: 0,
      productWidth: 0,
      productHeight: 0,
      productWeight: 0,
      charge_per_kilo: 0,
      charge_per_cue: 0,
      charge_per_save: 0,
      selectPrice: 1,
      fileimg: null,
      showLightbox: false,
      images: [],
      checkemail: false,
    }
  },
  computed: {
    qcFieldsWait() {
      return [
        {
          key: 'sequence', label: this.$t('sequence'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'lot_name', label: this.$t('lotSequence'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'postnumber', label: this.$t('parcelCode'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-left',
        },
        {
          key: 'qc_date', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'remark', label: this.$t('remarks'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customerCode'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'qc_fileimg', label: this.$t('attachedFile'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'product_amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'weight', label: this.$t('weight'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'width', label: this.$t('widthCm'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'height', label: this.$t('heightCm'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'long', label: this.$t('lengthCm'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'cmb', label: this.$t('cubic'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'collocate_wh_name', label: this.$t('arrange'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: 'Actions', thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    qcFieldsSuccess() {
      return [
        {
          key: 'sequence', label: this.$t('sequence'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'lot_name', label: this.$t('lotSequence'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'postnumber', label: this.$t('parcelCode'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-left',
        },
        {
          key: 'qc_date', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'remark', label: this.$t('remarks'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customerCode'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'product_amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'weight', label: this.$t('weight'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'width', label: this.$t('widthCm'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'height', label: this.$t('heightCm'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'long', label: this.$t('lengthCm'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'cmb', label: this.$t('cubic'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'collocate_wh_name', label: this.$t('arrange'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: 'Actions', thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.GetData()
    // this.GetLotList()
  },
  methods: {
    async Deletefileimg() {
      const value = await this.$bvModal.msgBoxConfirm('คุณต้องการลบไฟล์แนบ ?', {
        title: 'ยืนยันการลบ',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'warning',
        okTitle: 'ยืนยัน',
        cancelTitle: 'ยกเลิก',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      if (value) {
        this.fileimg = null
      }
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('QCPostnumber', picture) }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    handleFileChanges(event) {
      const filescus = event.target.files
      const reader = new FileReader()
      const filepath = filescus[0]
      reader.onload = e => {
        // console.log(e.target.result)
        // console.log(e.target.result)
        this.fileimg = e.target.result
        // console.log(this.filecusimg)
        // e.target.result
        // console.log(this.namemap)
      }
      reader.readAsDataURL(filepath)
    },
    time(data) {
      return moment(data).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss')
    },
    // async SearchPostnumber() {
    //   try {
    //     const obj = {
    //       ID: this.find_payment_name,
    //     }
    //     const { data: res } = await this.$http.post('/', obj)
    //     console.debug(obj)
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    checkInput() {
      if (/\s/.test(this.find_payment_name) || /\t/.test(this.find_payment_name)) {
        this.find_payment_name = this.find_payment_name.replace(/\s/g, '').replace(/\t/g, '')
      }
    },
    checkInputWait() {
      if (/\s/.test(this.parcelCode) || /\t/.test(this.parcelCode)) {
        this.parcelCode = this.parcelCode.replace(/\s/g, '').replace(/\t/g, '')
      }
    },
    async FindPostnumber() {
      try {
        this.showOver = true
        // this.getpost()
        const obj = {
          ID: this.find_payment_name,
        }
        const { data: res } = await this.$http.post('qcpostnumber/findpostnumber', obj)
        this.PostData = res
        this.showOver = false
        this.fileimg = null
        // eslint-disable-next-line no-underscore-dangle
        const index = this.QcData.qc_postnumber.findIndex(ele => ele.postnumber_id == this.PostData._id)
        if (index > -1) {
          return this.makeToast('danger', 'รหัสพัสดุซ้ำ', `ไม่สามารถเพิ่มรหัสพัสดุ ${this.find_payment_name} ได้ เนื่องจากรหัสพัสดุซ้ำ`)
        }

        // const value = null
        // if (this.PostData.lot_id) {
        //   value = await this.$bvModal.msgBoxConfirm(`รหัสพัสดุนี้ ${this.find_payment_name} ล็อต ${this.PostData.lot_name}`, {
        //     title: 'ยืนยันการเพิ่มข้อมูลและนำออกจากล็อต',
        //     size: 'sm',
        //     buttonSize: 'sm',
        //     okVariant: 'warning',
        //     okTitle: 'ยืนยัน',
        //     cancelTitle: 'ยกเลิก',
        //     footerClass: 'p-2',
        //     hideHeaderClose: false,
        //     centered: true,
        //   })
        // } else {
        //   value = await this.$bvModal.msgBoxConfirm(`ต้องการเพิ่มรายการ ${this.find_payment_name}?`, {
        //     title: 'ยืนยันการเพิ่มข้อมูล',
        //     size: 'sm',
        //     buttonSize: 'sm',
        //     okVariant: 'success',
        //     okTitle: 'ยืนยัน',
        //     cancelTitle: 'ยกเลิก',
        //     footerClass: 'p-2',
        //     hideHeaderClose: false,
        //     centered: true,
        //   })
        // }
        if (this.PostData) {
          this.showOver = true
          this.OnPostnumber = this.PostData
          // this.getpost()
          this.productLong = this.PostData.long
          this.productWidth = this.PostData.width
          this.productHeight = this.PostData.height
          this.productWeight = this.PostData.weight
          this.charge_per_cue = this.PostData.charge_per_cue
          this.charge_per_kilo = this.PostData.charge_per_kilo
          this.charge_per_save = this.PostData.charge_per_save
          this.fileimg = this.PostData.qc_fileimg || null
          if (this.charge_per_save === this.charge_per_cue) {
            this.selectPrice = 2
          } else {
            this.selectPrice = 1
          }
          // console.debug(this.OnPostnumber)
          // console.debug(this.PostData)
          this.$bvModal.show('lot-modal')
          // this.$refs[''].show()
          // const Obj = {
          //   ID: this.ID,
          //   PostData: this.PostData,
          //   qc_date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
          // }
          // const { data: res2 } = await this.$http.post('qcpostnumber/searchPostnumber', Obj)
          // qcpostnumber/updateqc
          // this.QcData = res2.data
          // this.qcWait = res2.Post_cn
          // this.qcSuccess = res2.Post_th
          // this.totalQcWait = this.qcWait.length
          // this.totalQcSuccess = this.qcSuccess.length
          this.showOver = false
        } else {
          this.OnPostnumber = null
          this.selectPrice = 1
          this.fileimg = null
          this.productLong = 0
          this.productWidth = 0
          this.productHeight = 0
          this.productWeight = 0
          this.charge_per_cue = 0
          this.charge_per_kilo = 0
          this.charge_per_save = 0
        }
      } catch (err) {
        this.showOver = false
        this.selectPrice = 1
        this.fileimg = null
        this.productLong = 0
        this.productWidth = 0
        this.productHeight = 0
        this.productWeight = 0
        this.charge_per_cue = 0
        this.charge_per_kilo = 0
        this.charge_per_save = 0
        this.OnPostnumber = null
        this.$bvToast.toast(err.response.data.mes, {
          title: 'เกิดข้อผิดพลาด',
          variant: 'danger',
          solid: true,
          autoHideDelay: 2000,
        })
      }
    },
    async GetData() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/qcpostnumber/show', { params })
        this.QcData = res.data
        this.qcWait = res.Post_cn
        this.qcSuccess = res.Post_th
        this.totalQcWait = this.qcWait.length
        this.totalQcSuccess = this.qcSuccess.length
      } catch (error) {
        console.log(error)
      }
    },
    EditType(data) {
      this.$router.push({ name: 'admin-user-type-edit', params: { id: data.id } })
    },
    DeletePayment(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'ยืนยัน',
        cancelTitle: 'ยกเลิก',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const obj = {
              ID: this.ID,
              payment_id: data.payment_id,
            }
            this.$http.post('paymentQC/removepayment', obj).then(() => {
              this.GetData()
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async DeleteData(data) {
      try {
        const value = await this.$bvModal.msgBoxConfirm(`ยืนยันการยกเลิก QC ${this.$t('parcelCode')} ${data.postnumber}?`, {
          title: 'ยืนยันการยกเลิก QC',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'ยืนยัน',
          cancelTitle: 'ยกเลิก',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })

        if (value) {
          this.showOver = true
          const obj = {
            ID: this.ID,
            PostData: data,
          }
          // const IndexCn = this.qcSuccess.findIndex(item => item.postnumber === data.postnumber)
          this.$http.post('/qcpostnumber/removepostnumber', obj)
            .then(response => {
              if (response.data.data) {
                this.showOver = false
                this.makeToast('success', 'ลบ QC', 'ลบ QC สำเร็จ')
                this.GetData()
              }

              // this.qcWait.push(data)
              // this.qcSuccess.splice(IndexCn, 1)
              // this.totalQcWait = this.qcWait.length
              // this.totalQcSuccess = this.qcSuccess.length
            })
            .catch(err => {
              this.makeToast('danger', 'ลบ QC', 'ลบ QC ไม่สำเร็จ')
              this.showOver = false
              console.log(err)
            })
        }
      } catch (err) {
        this.$bvToast.toast(err.response.data.mes, {
          title: 'เกิดข้อผิดพลาด',
          variant: 'danger',
          solid: true,
          autoHideDelay: 2000,
        })
      }
    },
    async UpdataRemark() {
      try {
        const obj = {
          ID: this.ID,
          remark: this.QcData.remark,
        }
        await this.$http.post('/qcpostnumber/updateremark', obj)
        this.makeToast('success', 'แก้ไขข้อมูล', 'แก้ไขข้อมูลหมายเหตุสำเร็จ')
      } catch (error) {
        console.log(error)
        this.makeToast('danger', 'แก้ไขข้อมูล', 'แก้ไขข้อมูลหมายเหตุไม่สำเร็จ')
      }
    },
    makeToast(variant = null, title, mes) {
      this.$bvToast.toast(mes, {
        title,
        variant,
        solid: true,
      })
    },
    async addParcelCode() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.showOver = true
          // this.checkpost = false
          // this.LotDataID = null
          // eslint-disable-next-line no-underscore-dangle
          const IndexCn = this.qcWait.findIndex(item => item.postnumber === this.parcelCode)
          if (IndexCn !== -1) {
            const PostnumberWait = this.qcWait[IndexCn]
            if (PostnumberWait) {
              const value = await this.$bvModal.msgBoxConfirm(`ยืนยัน QC ${this.$t('parcelCode')} ${this.parcelCode}?`, {
                title: 'ยืนยัน QC',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'warning',
                okTitle: 'ยืนยัน',
                cancelTitle: 'ยกเลิก',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
              })
              if (value) {
                const obj = {
                  ID: this.ID,
                  IDPost: PostnumberWait._id,
                }
                this.$http.post('/qcpostnumber/UpdateQcWaitAdmin', obj)
                  .then(response => {
                    if (response.data.status) {
                      this.parcelCode = null
                      this.GetData()
                      this.showOver = false
                      this.makeToast('success', 'ยืนยัน QC', 'ยืนยัน QC สำเร็จ')
                    }
                  }).catch(err => {
                    this.makeToast('danger', 'ยืนยัน QC', 'ยืนยัน QC ไม่สำเร็จ')
                    console.log(err)
                    this.showOver = false
                  })
              }
            } else {
              this.showOver = false
            }
            // this.OnPostnumber.index = IndexCn
            // this.$refs['lot-modal'].show()
          } else {
            this.showOver = false
            this.makeToast('danger', 'ยืนยัน QC', 'ไม่พบรหัสพัสดุนี้')
          }
        }
      })
    },
    GetLotList() {
      // this.showOver = true
      this.$http.get('/qcpostnumber/getlot')
        .then(res => {
          this.LotDataList = res.data
          this.LotDataList = this.LotDataList.map(item => ({
            ...item,
            text: `${item.transportType === 1 ? 'รถ' : 'เรือ'}/${item.name_lot}`,
          }))
          // eslint-disable-next-line no-underscore-dangle
          // this.LotDataID = this.LotDataList[0]._id
          this.showOver = false
        })
        .catch(err => {
          this.makeToast('danger', 'ยืนยัน QC', err.response.data.mes)
          // this.showOver = false
        })
    },
    getpost(data) {
      // this.checkpost = false
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this.OnPostnumber = data
        this.fileimg = data.qc_fileimg
        this.productLong = data.long
        this.productWidth = data.width
        this.productHeight = data.height
        this.productWeight = data.weight
        this.charge_per_cue = data.charge_per_cue
        this.charge_per_kilo = data.charge_per_kilo
        this.charge_per_save = data.charge_per_save
        this.selectPrice = data.charge_per_kilo === data.charge_per_save ? 1 : 2
      } else {
        this.OnPostnumber = null
        this.selectPrice = 1
        this.fileimg = null
        this.productLong = 0
        this.productWidth = 0
        this.productHeight = 0
        this.productWeight = 0
        this.charge_per_cue = 0
        this.charge_per_kilo = 0
        this.charge_per_save = 0
      }
      this.$bvModal.show('lot-modal')
    },
    async DeleteDataWait(data) {
      if (data) {
        const value = await this.$bvModal.msgBoxConfirm(`ยกเลิกรอ QC ${this.$t('parcelCode')} ${data.postnumber}?`, {
          title: 'ยกเลิกรอ QC',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'ยืนยัน',
          cancelTitle: 'ยกเลิก',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        if (value) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            IDpost: data._id,
            ID: this.ID,
          }
          this.$http.post('/qcpostnumber/RemovePostnumberWait', obj)
            .then(response => {
              if (response.data.status) {
                this.GetData()
                this.makeToast('success', 'ลบ QC', 'ลบ QC สำเร็จ')
              }
            })
            .catch(err => {
              console.log(err)
              this.makeToast('danger', 'ลบ QC', 'ลบ QC ไม่สำเร็จ')
            })
        }
      }
    },
    checkedprice() {
      if (this.OnPostnumber) {
        if (this.productWeight && this.OnPostnumber.product_type.pro_kilo && this.OnPostnumber.product_amount) {
          const sum = this.productWeight * this.OnPostnumber.product_type.pro_kilo * this.OnPostnumber.product_amount
          this.charge_per_kilo = sum
          if (this.charge_per_kilo > this.charge_per_cue) {
            this.charge_per_save = this.charge_per_kilo
            this.selectPrice = 1
          } else if (this.charge_per_cue > 0) {
            this.charge_per_save = this.charge_per_cue
            this.selectPrice = 2
          }
        }
      }
    },
    checkedpricereq() {
      if (this.OnPostnumber) {
        if (this.productWidth && this.productLong && this.productHeight && this.OnPostnumber.product_type.pro_queue && this.OnPostnumber.product_amount) {
          const req = ((this.productWidth * this.productLong * this.productHeight) / 1000000) * this.OnPostnumber.product_type.pro_queue
          this.selectPrice = 2
          this.charge_per_cue = req * this.OnPostnumber.product_amount
          if (this.charge_per_cue > this.charge_per_kilo) {
            this.charge_per_save = this.charge_per_cue
            this.selectPrice = 2
          } else if (this.charge_per_kilo > 0) {
            this.charge_per_save = this.charge_per_kilo
            this.selectPrice = 1
          }
        }
      }
    },
    checksave() {
      if (this.OnPostnumber) {
        if (Number(this.charge_per_save) === Number(this.charge_per_kilo)) {
          return `${this.$t('key-175')} ${this.Commas(this.charge_per_save)}`
        }
        return `${this.$t('key-0')} ${this.Commas(this.charge_per_save)}`
      }
    },
    async SubmitLot() {
      try {
        // this.$refs.simpleRules.validate().then(async success => {
        //   console.debug(success)
        if (this.OnPostnumber) {
        // eslint-disable-next-line no-underscore-dangle
          // this.LotData = this.LotDataList.find(item => item._id === this.LotDataID._id)
          const value = await this.$bvModal.msgBoxConfirm(`รหัสพัสดุ: ${this.OnPostnumber.postnumber}`, {
            title: 'รอยืนยันการ QC พัสดุ',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'ยืนยัน',
            cancelTitle: 'ยกเลิก',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          if (value) {
            this.showOver = true
            if (this.selectPrice === 1) {
              this.charge_per_save = this.CommasUp(this.charge_per_kilo)
            } else {
              this.charge_per_save = this.CommasUp(this.charge_per_cue)
            }
            const obj = {
              ID: this.ID,
              PostData: this.OnPostnumber,
              productWeight: this.productWeight,
              productHeight: this.productHeight,
              productWidth: this.productWidth,
              productLong: this.productLong,
              charge_per_save: this.CommasUp(this.charge_per_save),
              charge_per_kilo: this.CommasUp(this.charge_per_kilo),
              charge_per_cue: this.CommasUp(this.charge_per_cue),
              charge_cue: this.CommasCue((this.productWidth * this.productLong * this.productHeight) / 1000000),
              qc_fileimg: this.fileimg,
            }
            this.$http.post('/qcpostnumber/updatepost', obj)
              .then(response => {
                this.showOver = false
                const res = response.data
                this.makeToast('success', 'ยืนยัน QC', 'ยืนยัน QC สำเร็จ')
                this.parcelCode = ''
                this.QcData = res.data
                this.qcWait = res.Post_cn
                this.qcSuccess = res.Post_th
                this.totalQcWait = this.qcWait.length
                this.totalQcSuccess = this.qcSuccess.length
                // this.$refs['lot-modal'].hide()
                this.$bvModal.hide('lot-modal')
              })
              .catch(() => {
                this.makeToast('danger', 'ยืนยัน QC', 'ยืนยัน QC ไม่สำเร็จ')
                this.showOver = false
              })
          }
        }
        // })
      } catch (err) {
        this.makeToast('danger', 'ยืนยัน QC', err.response.data.mes)
        this.showOver = false
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

// จำกัดความสูง select เมื่อ dropdown
.style-chooser .vs__dropdown-menu {
  max-height: 100px;
}
.box-input-file {
  border: 1px dashed #5f8cc7;
  color: #5f8cc7;
  border-radius: 5px;
  height: 40px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

</style>
                  <style lang="scss" scoped></style>
